import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"

const Header = props => {
  const height = props.height || 24
  const bannerColour = props.bannerColour || "white"
  const position = props.position || "fixed"

  return (
    <nav
      className={`flex items-center justify-center ${position} w-full z-10 h-${height} bg-${bannerColour}`}
    >
      <Link to="/">
        <Img
          className="w-56"
          fluid={props.imageData.file.childImageSharp.fluid}
          alt="Slow Glass logo"
          style={{ transform: "translateZ(0px)" }}
        />
      </Link>
      {props.children}
    </nav>
  )
}

Header.propTypes = {
  height: PropTypes.number,
  bannerColour: PropTypes.string,
}

Header.defaultProps = {
  height: 24,
}

export default Header
